import React, {useContext, useEffect,useRef, useState } from "react"
import { graphql } from "gatsby";
import Markdown from "markdown-to-jsx";

import Link from "../components/link";
import Layout from "../components/wrappers/layout"
import SEO from "../components/seo"
import SiteHeaderAlt from "../components/wrappers/site-header-alt"
import Header from '../components/header';
import { InlineWidget } from "react-calendly";
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider";
import { CustomHr } from "../components/CustomHr";
import TabbedList from "../components/wrappers/tabbed-list";
import TabbedItem from "../components/wrappers/tabbed-item";
import ServiceCard from "../components/service-card";


const Manifesto = (props) => {
  const data = props.data; 
  const { manifesto , headers, footers, general } = data.strapi;
  const { copyright } = manifesto;
  const { generalBackgroundColorDarkMode, generalBackgroundColorLightMode  , fontColorDarkMode, fontColorLightMode, imageBorderRadiusForServices } = general; 

  const language = props.pageContext.language
  const header = headers && headers.find((header)=>  header.language === language ); 
  const footer = footers && footers.find((footer)=>  footer.language === language ) || {}; 

  // getting  page first H1 to set as meta title and description if not set in strapi .
  const pageFirstHeading = (manifesto.sup || "") + " " + (manifesto.title ? manifesto.title : "");
  const seoTitle = manifesto.seo && manifesto.seo.metaData.metaTitle || pageFirstHeading ||"manifesto";
  const seoDescription = manifesto.seo && manifesto.seo.metaData.metaDescription || pageFirstHeading|| " manifesto";
  const seoKeywords = manifesto.seo && manifesto.seo?.metaData?.keywords;
  const {services, heading} = manifesto; 
  const state = useContext(GlobalStateContext);
  const darkMode = state.darkModePreference === 'moon'; 
  const sectionBackgroundColor = darkMode ? generalBackgroundColorDarkMode : generalBackgroundColorLightMode; 
  const websiteFontColor = darkMode ? fontColorDarkMode : fontColorLightMode; 
  if (copyright) {
    footer.copyright = copyright;
  }

  return (
    <Layout header={header} footer={footer} language={language} >
      <SEO
        websiteFontColor={websiteFontColor} 
        template="manifesto" 
        title={seoTitle} 
        description={seoDescription} 
        keywords={seoKeywords} 
        darkTheme={manifesto.darkTheme}  
        darkMode={darkMode} 
        websiteBackgroundColor={sectionBackgroundColor}
      />
      {/* <Hero heroEntries={caseStudyList} buttonLabel={caseStudyButtonLabel} /> */}
      <SiteHeaderAlt header={header && header}>
        {services && (
          <section className={"section section-small-margin"} style={{backgroundColor: sectionBackgroundColor}}>
            <Header  heading={heading} />
            <div className="tabbed tabbed--remove-top container-lg container">
              {(services && services.length > 0) && (
                <TabbedList>
                  {services.map((service, index) => (
                    <TabbedItem 
                      id={( index + 1 < 10 ? "0" : "")+(index+1)} 
                      group="services" 
                      heading={service?.heading} 
                      initiallyChecked={index === 0}
                      imageBorderRadiusForServices={imageBorderRadiusForServices}
                    >
                      <ServiceCard 
                        service={service} 
                        showHeading={false} 
                        language={language} 
                        imageBorderRadiusForServices={imageBorderRadiusForServices}
                        websiteFontColor={websiteFontColor}
                      />
                    </TabbedItem>
                  ))}
                </TabbedList>
              )}
            </div>
          </section>
        )}
      </SiteHeaderAlt>
    </Layout>
  )
}

export default Manifesto; 

export const pageQuery = graphql`
  query ($id: ID!, $language: String!) {
    allSitePage {
      nodes {
        path
      }
    }
    strapi {
     general {
        generalBackgroundColorDarkMode
        generalBackgroundColorLightMode
        fontColorDarkMode
        fontColorLightMode
        imageBorderRadiusForServices
     }
     manifesto(id: $id) { 
       copyright
       heading
       darkTheme
       services {
        heading
        media {
          media {
            url
          }
        }
        body {
          richText
        }
        link {
          label
          path
        }
       }
     }
      headers(where: {language: $language}) {
        ...SiteHeader
      }
      footers(where: { language: $language }) {
        ...SiteFooter
      }
    }
  }
`
